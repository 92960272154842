<template>
<div class="row mt-4">
    <div class="col-12">
      <PlanCleanChartComponent :ChartData="ChartData" Unit="kWh" :key="ChartData"/>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import PlanCleanChartComponent from '@/components/partial/PlanCleanChartComponent.vue';

export default {
  name: 'PlanCleanChart',
  components: {
    PlanCleanChartComponent
  },
  data: function () {
    return {
      listData:'',
      ChartData:{
        labels: ["2015", "2016", "2017", "2018", "2019", "2020", "2021"],
        datasets: [
          {
            label: "Solar Production",
            data: [0, 15, 75, 20, 20, 55, 40],
            lineTension: 0,
            fill: false,
            borderColor: 'rgb(32, 201, 151)',
            backgroundColor: 'rgb(32, 201, 151)',
            
          },
          {
            label: "Pvsyst",
            data: [0, 35, 60, 60, 65, 30, 70],
            lineTension: 0,
            fill: false,
            borderColor: 'rgb(53, 43, 108)',
            backgroundColor: 'rgb(53, 43, 108)'
          }
          
        ]
      },

      
    }
  },
  mounted() {
    //  this.getlistData()
  },
  methods: {
    setChart(){

    },
    async getlistData(){
      try {
        let data = {
          SIDE_ID:'0'
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetMainDashboard',data,'auth');
        this.listData = getAPI.data.data[0]
        // GASCO2: "1277.27"
        // LOCATION: "53/1/3 Moo 7 Suk Samat Road ,Tambon Na Pa Ampor Mueang Chonburi ,Changwa Thailand 20000"
        // PEAKPOW: "9.90"
        // PLANTNAME: "AJAWIT KALAWANTAVANICH"
        // TREES: "38.12258970"
        // UPD: "20210715 20:18"

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .card-progress{
//   background: #fff;
//   box-shadow: 0 10px 30px rgb(146 153 184 / 15%);
//   border-radius: 10px;
//   padding: 25px;
// }
.card-overview-progress{
  margin-top:0px !important;
  min-height: 50px !important;
  max-width: 100% !important;
  .card-body{
    margin-top:0px !important;
    padding:0px;
  }
}

.card-progress__summerys{
  margin-bottom: 10px;
}
.min-hight{
  min-height: 300px;
}
.cashflow-display__single{
  margin-bottom: 0px !important;
}
</style>
