<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-12">
                            <BreadCrumbDate defaultType="YEAR" namePage="Cleaning Report" :hideToday="true" :hideMonth="true" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3">
                          <CompanyDetail />
                          <SelectPlant />
                          <YearlyDetail/>
                        </div>
                        <div class="col-12 col-md-9">
                          <PlanCleanChart/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
// @ is an alias to /src
import BreadCrumbDate from '@/components/main/BreadCrumbDate.vue';
import CompanyDetail from '@/components/main/CompanyDetail.vue';
import SelectPlant from '@/components/main/SelectPlant.vue';
import YearlyDetail from '@/components/cleaning/YearlyDetail.vue';
import PlanCleanChart from '@/components/cleaning/PlanCleanChart.vue';

export default {
  name: 'Cleaning',
  components: {
    BreadCrumbDate,
    CompanyDetail,
    SelectPlant,
    YearlyDetail,
    PlanCleanChart,
  },
  data: function () {
    return {
        selectedMonth:null
      }
    },
  mounted() {
    this.$store.dispatch('display/SET_pageName',"Cleaning Report")
  },
  methods: {
 
  }
}

</script>